import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

function Index() {
  const router = useRouter();

  useEffect(() => {
    router.push('https://www.buildable.dev');
  }, []);

  return <p>Redirecting...</p>;
}

export default Index;
